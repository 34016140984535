@use "../base" as *;

.home {

  h1 {
    color: $col-cream;
  }

  .center {
    margin-bottom: 2rem;
  }

  .header {
    align-items: start;
    background: linear-gradient( $col-purple, transparent 80%, transparent 100%); 
    height: 120px;
    z-index: 1;

    .logo,
    .plus {
      border-color: $col-cream;
      color: $col-cream;
    }
  }

  .landing {
    display: grid;
    grid-template-columns: 1fr;
    // grid-template-rows: 100vh 1fr;
    margin-bottom: 2rem;
    grid-template-rows: 80vh auto;
    height: auto;

    @media screen and (min-width: 960px) {
      // grid-template-columns: 50% 50%;
    // grid-template-rows: 100vh 1fr;
    margin-bottom: 0;
      // grid-template-rows: 90vh auto;
    }

    .hero {
      align-items: stretch;
      background-color: $col-purple;
      display: flex;
      position: relative;
      grid-column: 1 / span 2;
    grid-row: 1;
    min-height: auto;
    height: auto;

      &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        min-height: 120px;
        min-width: 100%;
        mix-blend-mode: multiply;
      }

      .video {
        animation: fade-in 0.5s ease-in forwards;
        object-fit: cover;
      }

    }
  }

  .list {
    .yline {
      height: calc(100% - 82px);
      left: 16.5px;
      top: 66px;

      @media screen and (min-width: 768px) {
        height: calc(100% - 60px);
        top: 0;
        left: 50%;
      }
    }
  }

  .intro {
    align-items: center;
    background: linear-gradient(transparent, $col-purple 120px, $col-purple 100%); 
    grid-column: 1 / span 2;
    grid-row: 2;
    margin: -120px auto 0 auto;

    justify-self: center;
    position: static;
    padding-bottom: 6rem;
    z-index: 1;

    > * {
      width: 100%;
    }
  }

  .section {
    &:nth-of-type(3) {
      .heading,
      .item {
        flex-direction: row-reverse;
      }

      .heading {
        justify-content: start;
      }

      .item {
        text-align: right;
      }
    }
  }

  .title {
    letter-spacing: 0.08rem;
  }
}

