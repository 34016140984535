@use "variables" as *;

@keyframes blur-in {
  0% { filter: blur(10px) }
  100% { filter: blur(0px) }
}

@keyframes expand {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}

@keyframes hide {
  0% {
    opacity: 1;
    transform: scaleX(1);
  }
  50% {
    opacity: 0;
  }
  100% {
    transform: scaleX(0);
  }
}

@keyframes fade-in {
  0% { 
    opacity: 0;
  }
  100% { 
    opacity: 1;
  }
}

@keyframes typing {
  0% { width: 0 }
  100% { width: 100% }
}