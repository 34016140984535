@use "../base" as *;


.button {
  &-cream {
    @extend %button
  }

  &-purple {
    @extend %button;

    background: $col-cream;
    border: 1px solid $col-purple;
    color: $col-purple;
    transition: border 150ms linear, font-weight 150ms linear;

    &.active {
      border: 3px solid $col-purple;
      font-weight: 600;
      transition: border 150ms linear, font-weight 150ms linear;
    }
  }
}


.center {
  @extend %flex-row-center;

  min-width: 100%;
}

.cream {
  color: $col-cream;
  
  &:visited {
    color: $col-purple;
  }
}

.purple {
  color: $col-purple;

  &:visited {
    color: $col-purple;
  }
}


.footer {
  background: linear-gradient(transparent, $col-purple 80%, $col-purple 100%); 
  min-height: 100vh;
  position: relative;   

  .links {
    align-items: center;
    bottom: 3rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    left: 50%;
    position: absolute;
    transform: translateX(-50%); 

    @media screen and (min-width: 768px) {
      flex-direction: row;
    }
  }

  .link {
    @extend %button;
    @extend %flex-row-center;

    color: $col-cream;
    font-size: 0.75rem;
    font-weight: 300;
    text-decoration: none;
  }

  .yline {
    height: calc(100% - 280px);

    @media screen and (min-width: 768px) {
      height: calc(100% - 120px);
    }
  }
}



.intro {
  align-items: end;
  background: linear-gradient(transparent, $col-purple 70%, $col-purple 100%); 
  color: $col-cream;
  display: flex;
  flex-direction: column;
  grid-column: 1;
  grid-row: 1 / span 2;
  justify-content: end;
  gap: 1rem;
  padding: 3rem 2rem 3rem 2rem;

  > * {
    width: 90%;
    max-width: 650px;

    @media screen and (min-width: 960px) {
      width: 80%;
    }
  }

  .field {
    h2 {
      font-weight: 500;
      margin-bottom: 0.5rem;
    }

    p {
      font-size: 14px;
    }

    &:first-of-type {
      margin-top: 1rem;
    }
  }

  .title {
    color: $col-cream;
  }
  
}

.landing {
  background-color: $col-purple;
  height: 100vh;
  overflow: hidden;

  .picture {
    img {
      height: 100vh;
      object-fit: cover;
      width: 100%;
    }
  }

  .hero {
    height: 100vh;
  }

  .intro {
    align-items: center;
    bottom: 0;
    gap: 0.5rem;
    left: 0;
    min-width: 100%;
    position: sticky;
    z-index: 1;

    > * {
      width: auto;
      text-decoration: none;
    }

  }
}

.list {
  @extend %two-columns; 

  justify-self: center;
  min-width: 90%;
  position: relative;
  
  .cta {
    @extend %flex-row-center;

    
    grid-column: span 2;
    margin: 3rem 0 0 1rem;
    justify-content: flex-start;

    @media screen and (min-width: 768px) {
      justify-content: center;
      margin: 3rem 0 0 0;
    }

    .xline {
      width: calc(50% - 80px);
      
      @media screen and (min-width: 768px) {
        display: none
      }
    }
  }


  .heading {
    @extend %flex-row-center;

    gap: 1rem;
    grid-column: 1 / span 2;
    margin: 3rem 0 0 1rem;
    z-index: 1;
    flex-direction: row-reverse;
    justify-content: start;

    @media screen and (min-width: 768px) {
      flex-direction: row;
      gap: 2rem;
      grid-column: 1 ;
      justify-content: end;
      margin: 0;
    }

    .xline {
      // display: none;

      // @media screen and (min-width: 550px) {
      //   display: block;
      // }
    }

    

    h3 {
      font-family: $font-comp;
      font-size: 2.25rem;
      font-weight: 900;
      line-height: 1;
    }
  }

  .item {
    @extend %flex-row-center;

    gap: 1rem;
    justify-content: start;
    margin: 6rem 0 6rem 1rem;
    position: relative;

    &:hover {
      opacity: 1;
    }

    @media screen and (min-width: 768px) {
      margin: 6rem 0 6rem 0;
    }

    .link {
      @extend %flex-row-center;

      gap: 1rem;
      padding-right: 2em;
      text-decoration: none;

      &:hover {
        opacity: 0.5;
      }
      
  
    }
    

    h4 {
      font-weight: 300;
    }
  }


  .yline {
    height: calc(100% - 60px);
    line {
      stroke: $col-purple;
    }
  }
}

.fade-in {
  animation: fade-in 0.5s ease-in forwards;
}

.logo {
  // animation: typing 1.7s steps(17, end);
  overflow: hidden; 
  white-space: nowrap;
}

.plus {
  @extend %flex-row-center;

  border: 1px solid $col-purple;
  border-radius: 20px;
  font-size: 1.5rem;;
  line-height: 30px;
  max-height: 30px;
  max-width: 30px;
  min-height: 30px;
  min-width: 30px;
}

.title {
  color: $col-purple;
  font-family: $font-comp;
  font-size: 2.5rem;
  font-weight: 900;
  line-height: 1.2;
  margin: 0.5rem 0 1rem;
}

.xline {
  height: 1px;
  min-width: 50px;

  line {
    stroke: $col-purple;
    stroke-width:1
  }
}

.yline {
  height: 100%;
  left: 50%;
  position: absolute;
  top: 0;
  transform: translateX(-50%);

  line {
    stroke: $col-cream;
    stroke-width:1
  }
}


