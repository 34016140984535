@use "variables" as *;

%button {
  border: 1px solid $col-cream;
  color: $col-cream;
  font-size: 0.75rem;
  font-weight: 300;  
  height: 2rem;
  width: 140px;
  text-decoration: none;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

// %container {
//   max-width: 65rem;
//   padding: 25px 25px 50px;


//   @media (max-width: 500px) {
//     padding: 16px 16px 50px;
//   }
// }

%flex-row-center {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

%two-columns {
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: 768px) {
    grid-template-columns: 50% 50%;
  }
}