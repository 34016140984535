@use "../base" as *;

.collection {

  .center {
    .yline {
      height: 100%;
    }
  }

  .filter {
    background-color: $col-cream;
    border-bottom: 1px solid $col-purple;
    margin: 0;
    padding: 0;
    position: sticky;
    top: 0;
    z-index: 2;

    @media screen and (min-width: 768px) {
      background-color: transparent;
      border-bottom: none;
      z-index: 1;
    }

    h4 {
      margin-right: 1rem;
    }

    .row {
      @extend %flex-row-center;

      

      gap: 1rem;
      justify-content: end;
      padding: 2rem;

      @media screen and (min-width: 768px) {
        width: 50%;
      }

      .xline {
        display: none;

        @media screen and (min-width: 768px) {
          display: block
        }
      }

      @media screen and (min-width: 768px) {
        padding: 4rem 0;
        width: 50%;
      }
    }

    .yline {
      display: none;

      @media screen and (min-width: 768px) {
        display: block
      }

      line {
        stroke: $col-purple;
      }
    }

    .xline {
      width: 120px;
    }
  }

  .list {
    grid-template-columns: 1fr;
    width: 100%;

    .heading {
      background-color: $col-cream;
      border: 1px solid $col-purple;
      border-radius: 60px;
      font-weight: 600;
      height: 80px;
      justify-content: center;
      margin: 2rem auto;
      padding: 2rem 1rem;
      position: sticky;
      top: 97px;
      z-index: 2;
      width: 80px;

      @media screen and (min-width: 768px) {
        top: 40px;
      }
    }

    li {
      display: flex;
      justify-content: end;

      &:nth-of-type(odd) {
        justify-content: end;
      }

      .item {
        background-color: $col-cream;
        flex-direction: column;
        margin: 2rem 0;
        padding: 2rem 1rem;
        width: 100%;
        text-align: center;
        text-decoration: none;

        @media screen and (min-width: 768px) {
          flex-direction: row;
          padding: 0;
          text-align: left;
          width: 50%;
          z-index: 1;
        }

        .xline {
          display: none;

          @media screen and (min-width: 768px) {
            display: block;
          }
        }

        &:hover {
          opacity: 0.5;

          .plus {
            transform: scale(1.25);
            transition: transform 150ms linear;
          }
        }

        .plus {
          transition: transform 150ms linear;
        }

      }
    }
  }

}